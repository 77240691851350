import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    post:'pc1920',
  },
  getters: {
  },
  mutations: {
    postChange(state, e) {
      state.post = e
    }
  },
  actions: {
  },
  modules: {
  }
})
