export const whitchPost = function () {
    var innerWidth = window.innerWidth

    if ( innerWidth < 768 ) {
        return 'mobile';
    } else if ( innerWidth >= 768 && innerWidth < 1200) {
        return 'pad';
    } else if ( innerWidth >= 1200 && innerWidth <= 1920) {
        return "pc1920";
     }else{
        return 'pc2080';
    }
}