<template>
  <div id="app">
    <!-- pc1920 -->
    <div id="menu_pc1920">
      <div @click="jumpTohome()" style="cursor: pointer;" id="logo"></div>
      <div id="menu_items">
        <a @click="jumpTohome()" class="menu_item">首页</a>
        <a @click="jumpToSecurity()" class="menu_item">防伪查询</a>
        <a @click="jumpToAbout()" class="menu_item">关于我们</a>
        <!-- <a @click="getThere('page4')" class="menu_item">页面锚点2</a>
        <a @click="getThere('page2')" class="menu_item">页面锚点3</a>
        <a @click="getThere('page7')" class="menu_item">页面锚点4</a>
        <a @click="getThere('page8')" class="menu_item">页面锚点5</a> -->
      </div>
      <!-- <div v-show="pageType === 2" id="menu_items">
        <a @click="jumpTohome()" style="color: rgb(220, 0, 38);" class="menu_item">返回首页</a>
      </div>
      <div v-show="pageType === 3" id="menu_items">
        <a @click="jumpTohome()" style="color: rgb(220, 0, 38);" class="menu_item">返回首页</a>
      </div> -->
      <div id="logo" style="visibility: hidden;"></div>
    </div>
    <router-view/>
    <div id="footer_pc1920">
      <div style="width: 600px;margin: auto;">
        <div style="display: flex;justify-content: space-between;">
          <img style="height: 60px;" src="./assets/img/pc1920/logo.png" alt="">
          <div>
            <div style="margin-bottom: 6px;">联系我们</div>
            <div style="margin-bottom: 6px;">邮箱：yujutea@163.com</div>
            <div style="margin-bottom: 6px;">地址：广州市天河区珠村东环路110号108房(仅限办公)</div>
          </div>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;">
          Copyright © yujutea.com 版权所有&nbsp;&nbsp;
          <img src="./assets/img/pc1920/beian.png" alt="">
          <a style="color: #333;" href="https://beian.miit.gov.cn/#/Integrated/index">&nbsp;粤ICP备19135983号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { whitchPost } from '@/assets/js/utils/utils.js';  
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: 'App',
  data() {
    return {
      timer:null,
      pageType:1
    }
  },
  methods: {
    jumpToSecurity() {
      try {
        this.$router.push('/security')
      } catch (error) {
        // 
      }
    },
    jumpToAbout() {
      try {
        this.$router.push('/about')
      } catch (error) {
        // 
      }
    },
    jumpTohome() {
      try {
        this.$router.push('/')
      } catch (error) {
        // 
      }
    },
    getThere(id) {
      // 通过ref名获取元素在页面中的位置并滚动至此
      // this.$el.querySelector(".cat_box")
      document.getElementById(id)
      .scrollIntoView({ block: "start", behavior: "smooth" });  
    },
    postChange () {
      this.$store.commit('postChange',whitchPost())
    },
    clearAllTIggers() {
      var triggers = ScrollTrigger.getAll();
      triggers.forEach((trigger) => {// 消除绑定ScrollTrigger的动画
        trigger.kill();
      });
      ScrollTrigger.clearMatchMedia(); // 消除绑定MatchMedia()的动画
    }
  },
  mounted() {
    this.postChange()
    window.onresize = () => {
      if(this.timer !== null){
          clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        if ( whitchPost() != this.$store.state.post ) {
          this.clearAllTIggers()
          this.postChange()
        }
      },200)
    }
  },
  // 监听,当路由发生变化的时候执行
  watch:{
    $route(to){
      if ( to.path === '/security' ) {
        this.pageType = 3
      } else if ( to.path === '/about' ) {
        this.pageType = 2
      } else if ( to.path === '/' ) {
        this.$nextTick(()=> {
          document.querySelector('#page4').scrollIntoView(true)
        })
        this.pageType = 1
      }
    }
  },
  created() {
      document.title = '御举茗茶业'
  },
}
</script>

<style lang="scss">
  html, body {
    overflow: hidden;
    // background-color: rgb(245, 245, 247);
  }
  @import './assets/css/pc1920.scss';
  // /* mobile */
  // @media only screen and (max-width: 767px) {
  //   @import './assets/css/mobile.scss';
  // }
  // /* pad */
  // @media only screen and (min-width: 768px) and (max-width: 1199px) {
  //   @import './assets/css/pad.scss';
  // }
  // /* 1920*1080 */
  // @media only screen and (min-width: 1200px) and (max-width: 1920px) {
  //   @import './assets/css/pc1920.scss';
  // }
  // /* 2080*1440 */
  // @media only screen and (min-width: 1921px) {
  //   @import './assets/css/pc2080.scss';
  // }
</style>
