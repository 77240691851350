<template>
  <div class="home">
    <!-- pc1920 -->
    <div id="home_pc1920">
      <div id="page1">
        <div style="width: 1000px;margin: auto;height: 211px;right: 0;left: 0;top: 0;bottom: 0;display: flex;flex-direction: column;align-items: center;position: absolute;">
          <div id="slogan"></div>
          <div id="slogan2"></div>
        </div>
      </div>
      <div id="page4">
        <div style="width: 1400px;display: flex;height: 670px;right: 0;left: 0;top: 0;bottom: 0;justify-content: space-between;align-items: center;margin: auto;position: absolute;">
          <div style="display: flex;flex-direction: column;align-items: flex-end;">
            <!-- <div id="page4_1" style="text-align: right;font-size:30px;font-weight: bold;line-height: 1.4;">此处文本此处文本</div> -->
            <div id="page4_2" style="text-align: right;font-size:62px;font-weight: bold;line-height: 1.13;margin-bottom: 30px;">让世人,<br>拥有一盏好茶</div>
            <div id="page4_3" style="text-align: right;font-size:15px;color: rgba(34,37,48,.6);font-weight: bold;line-height: 1.4;">御举茗茶叶有限公司 致力于茶叶产业发展和客户需求的承诺，<br>以及对质量，创新和可持续发展的追求，始终把产品质量置于首位，坚持使用<br>最优质的原材料，确保产品符合高质量,高标准</div>
            <!-- <div @click="jumpToDetail()" id="page4_4">查看更多</div> -->
          </div>
          <img id="page4_img" src="../assets/img/pc1920/img11.jpg" alt="">
        </div>
      </div>
      <div id="page2">
        <div id="page2_bg"></div>
        <div style="width: 1000px;position: absolute;bottom: 0;left: 0;right: 0;top: 0;color: white!important;margin: auto;display: flex;flex-direction: column;justify-content: center;">
          <!-- <div id="page2_1" style="font-size:30px;font-weight: bold;line-height: 1.4;">此处文本此处文本此处文本</div> -->
          <div id="page2_2" style="font-size:62px;font-weight: bold;line-height: 1.13;margin-bottom: 30px;">YUJUTEA GROUP</div>
          <!-- <div id="page2_3" style="font-size:15px;font-weight: bold;line-height: 1.4;">此处文本此处文本此处文本此处文本此处文本此处文本<br>此处文本此处文本此处文本</div> -->
        </div>
      </div>
      <div id="page7">
        <div style="width: 1400px;display: flex;height: 670px;right: 0;left: 0;top: 0;bottom: 0;justify-content: flex-start;align-items: center;margin: auto;position: absolute;">
          <img id="page7_img" src="../assets/img/pc1920/bg4.jpg" alt="">
          <div style="margin-left: 130px;">
            <div id="page7_1" style="font-size:62px;font-weight: bold;line-height: 1.13;">高质量,高标准</div>
            <div id="page7_2" style="font-size:30px;font-weight: bold;line-height: 1.4;margin-bottom: 30px;">致力于挖掘和保护茶叶的独特价值<br>和历史传统<br>将优秀的茶叶文化传递给更多的人 </div>
            <div id="page7_3" style="font-size:15px;color: rgba(34,37,48,.6);font-weight: bold;line-height: 1.4;">我们追求卓越，不断提升茶叶的口感 香气和营养价值<br>为消费者提供优质的茶叶体验</div>
          </div>
        </div>
      </div>
      <div id="page8">
        <div style="width: 1400px;display: flex;height: 670px;right: 0;left: 0;top: 0;bottom: 0;justify-content: flex-end;align-items: center;margin: auto;position: absolute;">
          <div style="margin-right: 130px;">
            <!-- <div id="page8_1" style="text-align: right;font-size:30px;font-weight: bold;line-height: 1.4;">此处文本此处文本</div> -->
            <div id="page8_2" style="text-align: right;font-size:62px;font-weight: bold;line-height: 1.13;margin-bottom: 30px;">绿色,<br>可持续发展</div>
            <div id="page8_3" style="text-align: right;font-size:15px;color: rgba(34,37,48,.6);font-weight: bold;line-height: 1.4;">我们鼓励采用环保的种植方式，减少对士壤和水资源的影响<br>积极推广茶园生态建设和有机农业，以保护生态环境和提高茶叶品质</div>
          </div>
          <img id="page8_img" src="../assets/img/pc1920/bg5.jpg" alt="">
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div id="home_mobile">
      <div id="test"></div>
    </div>
  </div>
</template>

<script>
  import gsap from 'gsap'
  import { ScrollTrigger } from "gsap/ScrollTrigger";
  gsap.registerPlugin(ScrollTrigger);
  export default {
    name: 'App',
    data() {
      return {
        
      }
    },
    methods: {
      jumpToDetail() {
        this.$router.push('/about')
      },
      animation_pc1920() {
        // console.log('setAnimation_pc1920');
        // 页面1
        var page1_timeline = gsap.timeline()
        page1_timeline
        .fromTo("#home_pc1920 #slogan", 1, { y: 0 ,x:0 }, { y: -50 ,x:-50},'+=1')
        .fromTo("#home_pc1920 #slogan2", 1, { y: 20, autoAlpha: 0 ,x:50 }, { y: -50, autoAlpha: 1 ,x:50 },'+=0.5')
        .to('html',5,{})
        ScrollTrigger.create({
          animation: page1_timeline,
          trigger: '#home_pc1920 #page1',
          start: () => {
              return `50% center`
          },
          end: () => {
              return `+=150%`
          },
          // markers: true,
          id: 'page1_animation',
          scrub: true,
          pin: true
        });
        // 页面2
        var page2_timeline = gsap.timeline()
        page2_timeline
        // .fromTo("#home_pc1920 #page2", 1, { scale:1 }, { scale:1.1 })
        ScrollTrigger.create({
          animation: page2_timeline,
          trigger: '#home_pc1920 #page2',
          start: () => {
              return `top bottom`
          },
          end: () => {
              return `bottom top`
          },
          // markers: true,
          id: 'page2_animation',
          scrub: true,
        });
        // 页面2-1
        var page2_timeline1 = gsap.timeline()
        page2_timeline1
        // .fromTo("#home_pc1920 #page2_1", 1, { y: 50, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'+=1.5')
        .fromTo("#home_pc1920 #page2_2", 1, { y: 50, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'+=1.5')
        // .fromTo("#home_pc1920 #page2_3", 1, { y: 90, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        ScrollTrigger.create({
          animation: page2_timeline1,
          trigger: '#home_pc1920 #page2',
          start: () => {
              return `10% bottom`
          },
          end: () => {
              return `+=50%`
          },
          // markers: true,
          id: 'page2_animation',
          scrub: true,
        });
        // 页面3
        var page3_timeline = gsap.timeline()
        page3_timeline
        .fromTo("#home_pc1920 #page3_1", 1, { y: 50, autoAlpha: 0  }, { y: 0, autoAlpha: 1 })
        .fromTo("#home_pc1920 #page3_2", 1, { y: 70, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        .fromTo("#home_pc1920 #page3_3", 1, { y: 90, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        ScrollTrigger.create({
          animation: page3_timeline,
          trigger: '#home_pc1920 #page3',
          start: () => {
              return `10% bottom`
          },
          end: () => {
              return `+=60%`
          },
          // markers: true,
          id: 'page3_animation',
          scrub: true,
        });
        // 页面4
        var page4_timeline = gsap.timeline()
        page4_timeline
        .fromTo("#home_pc1920 #page4_img", 1, { y: 50, autoAlpha: 0  }, { y: 0, autoAlpha: 1 })
        // .fromTo("#home_pc1920 #page4_1", 1, { y: 50, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        .fromTo("#home_pc1920 #page4_2", 1, { y: 70, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        .fromTo("#home_pc1920 #page4_3", 1, { y: 90, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        // .fromTo("#home_pc1920 #page4_4", 1, { y: 90, autoAlpha: 0  }, { y: 0, autoAlpha: 1 })
        ScrollTrigger.create({
          animation: page4_timeline,
          trigger: '#home_pc1920 #page4',
          start: () => {
              return `30% bottom`
          },
          end: () => {
              return `+=60%`
          },
          // markers: true,
          id: 'page4_animation',
          scrub: true,
        });
        // // 页面5_1
        // var page51_timeline = gsap.timeline()
        // page51_timeline
        // .fromTo("#home_pc1920 #otca", 1, { y: 50, autoAlpha: 0  }, { y: 0, autoAlpha: 1 })
        // // .fromTo("#home_pc1920 #otca_tips", 1, { y: 70, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        // // .fromTo("#home_pc1920 #page3_3", 1, { y: 90, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        // ScrollTrigger.create({
        //   animation: page51_timeline,
        //   trigger: '#home_pc1920 #page5',
        //   start: () => {
        //       return `20% bottom`
        //   },
        //   end: () => {
        //       return `+=100%`
        //   },
        //   // markers: true,
        //   id: 'page51_animation',
        //   scrub: true,
        // });
        // // 页面5
        // var page5_timeline = gsap.timeline()
        // page5_timeline
        // .fromTo("#home_pc1920 #otca_line_sub", 1, { x: 0 }, { x: 280 },'+=2')
        // .fromTo("#home_pc1920 #otca_2", 1, { opacity: 0 }, { opacity: 1 },'-=1')
        // .fromTo("#home_pc1920 #otca_tip_sub1", 1, { color: 'rgb(220, 0, 38)' }, { color: 'grey' },'-=1')
        // .fromTo("#home_pc1920 #otca_tip_sub2", 1, { color: 'grey' }, { color: 'rgb(220, 0, 38)' },'-=1')
        // .fromTo("#home_pc1920 #description1", 2, { opacity: 1 }, { opacity: 0},'-=1')
        // .fromTo("#home_pc1920 #description2", 2, { opacity: 0 }, { opacity: 1},'-=1')
        // .to('html',4,{})
        // .fromTo("#home_pc1920 #otca_line_sub", 1, { x: 280 }, { x: 620 },'+=1')
        // .fromTo("#home_pc1920 #otca_2", 1, { opacity: 1 }, { opacity: 0 },'-=1')
        // .fromTo("#home_pc1920 #otca_3", 1, { opacity: 0 }, { opacity: 1 },'-=1')
        // .fromTo("#home_pc1920 #otca_tip_sub2", 1, { color: 'rgb(220, 0, 38)' }, { color: 'grey' },'-=1')
        // .fromTo("#home_pc1920 #otca_tip_sub3", 1, { color: 'grey' }, { color: 'rgb(220, 0, 38)' },'-=1')
        
        // .to('html',4,{})
        // .fromTo("#home_pc1920 #otca_line_sub", 1, { x: 620 }, { x: 950 },'+=1')
        // .fromTo("#home_pc1920 #otca_3", 1, { opacity: 1 }, { opacity: 0 },'-=1')
        // .fromTo("#home_pc1920 #otca_4", 1, { opacity: 0 }, { opacity: 1 },'-=1')
        // .fromTo("#home_pc1920 #otca_tip_sub3", 1, { color: 'rgb(220, 0, 38)' }, { color: 'grey' },'-=1')
        // .fromTo("#home_pc1920 #otca_tip_sub4", 1, { color: 'grey' }, { color: 'rgb(220, 0, 38)' },'-=1')
        // .to('html',4,{})
        // ScrollTrigger.create({
        //   animation: page5_timeline,
        //   trigger: '#home_pc1920 #page5',
        //   start: () => {
        //       return `50% center`
        //   },
        //   end: () => {
        //       return `+=350%`
        //   },
        //   // markers: true,
        //   id: 'page5_animation',
        //   scrub: true,
        //   pin: true
        // });
        // 页面6
        var page6_timeline = gsap.timeline()
        page6_timeline
        .fromTo("#home_pc1920 .img_item", 5, { x: 100 }, { x: -1700 },'+=1.5')
        // .fromTo("#home_pc1920 #slogan2", 1, { y: 20, autoAlpha: 0 }, { y: -50, autoAlpha: 1 },'+=0.5')
        .to('html',1,{})
        ScrollTrigger.create({
          animation: page6_timeline,
          trigger: '#home_pc1920 #page6',
          start: () => {
              return `35% center`
          },
          end: () => {
              return `+=180%`
          },
          // markers: true,
          id: 'page6_animation',
          scrub: true,
          pin: true
        });
        // 页面7
        var page7_timeline = gsap.timeline()
        page7_timeline
        .fromTo("#home_pc1920 #page7_img", 1, { y: 50, autoAlpha: 0  }, { y: 0, autoAlpha: 1 })
        .fromTo("#home_pc1920 #page7_1", 1, { y: 50, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        .fromTo("#home_pc1920 #page7_2", 1, { y: 70, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        .fromTo("#home_pc1920 #page7_3", 1, { y: 90, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        ScrollTrigger.create({
          animation: page7_timeline,
          trigger: '#home_pc1920 #page7',
          start: () => {
              return `30% bottom`
          },
          end: () => {
              return `+=60%`
          },
          // markers: true,
          id: 'page7_timeline',
          scrub: true,
        });
        // 页面8
        var page8_timeline = gsap.timeline()
        page8_timeline
        .fromTo("#home_pc1920 #page8_img", 1, { y: 50, autoAlpha: 0  }, { y: 0, autoAlpha: 1 })
        // .fromTo("#home_pc1920 #page8_1", 1, { y: 50, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        .fromTo("#home_pc1920 #page8_2", 1, { y: 70, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        .fromTo("#home_pc1920 #page8_3", 1, { y: 90, autoAlpha: 0  }, { y: 0, autoAlpha: 1 },'-=0.5')
        ScrollTrigger.create({
          animation: page8_timeline,
          trigger: '#home_pc1920 #page8',
          start: () => {
              return `30% bottom`
          },
          end: () => {
              return `+=60%`
          },
          // markers: true,
          id: 'page8_timeline',
          scrub: true,
        });
      },
      animation_pc2080() {
        // console.log('setAnimation_pc2080');
      },
      animation_pad() {
        // console.log('setAnimation_pad');
      },
      animation_mobile() {
        // console.log('setAnimation_mobile');
      },
      setAnimations() {
        this[`animation_${this.$store.state.post}`]()
      },
    },
    mounted() {
      this.setAnimations()
    },
    computed: {
      post_watch() {
        return this.$store.state.post
      },
    },
    watch: {
      post_watch() {
        this.setAnimations()
      }
    }
  }
</script>
